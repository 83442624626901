import React from 'react';

class Loader extends React.Component {

    render() {
        return (
            <>
            </>
        )
    }
}

export default Loader;
